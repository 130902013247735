import {
  AUTHENTICATED,
  SET_CURRENT_COURSE,
  SET_CURRENT_PROFILE,
  SET_TYPE,
  UNAUTHENTICATED,
  SET_PRODUCT_DETAILS,
  SET_IS_TRAIL_COURSE,
  SET_APP_NAME,
  SET_NOTIFICATIONS_COUNT
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";
import { Course, Student } from "../../models/student.model";

export interface IAuthState {
  authenticated: boolean;
  user?: Student;
  // currentProfile: Student;
  currentCourse?: Course | null;
  courseId?: number | null;
  type: "exam" | "class_room" | null;
  productDetails?: any,
  isTrialCourse: boolean,
  isCourseExpired: boolean,
  app_name: string | null,
  notifications: {liveClassCount: number, worksheetCount: number, messagesCount: number}
}

const initState: IAuthState = {
  authenticated: LocalStorage.getItem("USER") ? true : false,
  user: LocalStorage.getItem("USER"),
  // currentProfile: LocalStorage.getItem("CURRENT_PROFILE"),
  currentCourse: null,
  courseId: null,
  productDetails: LocalStorage.getItem("PRODUCT_DETAILS"),
  type: null,
  isTrialCourse: false,
  isCourseExpired: false,
  app_name: sessionStorage.getItem("APP_NAME"),
  notifications: {liveClassCount: 0, worksheetCount: 0, messagesCount: 0}
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: any, action: any) {
    const { authenticated, user, courseId, currentCourse } = action.payload;
    return {
      ...state,
      authenticated,
      user
    };
  },
  [UNAUTHENTICATED](state: any, action: any) {
    const { authenticated, currentCourse, courseId } = action.payload;

    return {
      ...state,
      authenticated,
      currentCourse,
      courseId
    };
  },
  // [SET_CURRENT_PROFILE](state: any, { payload }: any) {
  //   const { currentProfile } = payload;
  //   return { ...state, currentProfile };
  // },
  [SET_CURRENT_COURSE](state: any, { payload }: any) {
    const { course } = payload;
    return {
      ...state,
      currentCourse: course,
      courseId: course?.id
    };
  },
  [SET_TYPE](state: any, { payload }: any) {
    const { type } = payload;
    return {
      ...state,
      type,
    };
  },
  [SET_PRODUCT_DETAILS](state: any, { payload }: any) {
    const { product } = payload;
    return {
      ...state,
      productDetails: product,
    };
  },
  [SET_IS_TRAIL_COURSE](state: any, action: any) {
    const { istrail, isexpired } = action.payload;
    return {
      ...state,
      isTrialCourse: istrail,
      isCourseExpired: isexpired
    };
  },
  [SET_APP_NAME](state: any, action: any) {
    const { app_name } = action.payload;
    return {
      ...state,
      app_name: app_name,
    };
  },
  [SET_NOTIFICATIONS_COUNT](state: any, action: any) {
    const { notifications } = action.payload;
    return {
      ...state,
      notifications: notifications,
    };
  },
});

export default authReducer;
