import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import "./navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import TitleContainer from "../../../store/container/TitleContainer";
import { ITitleState } from "../../../store/reducers/titleReducer";
import BackIcon from "../../../assets/icon/back.svg";
import BackWhiteIcon from "../../../assets/icon/backWhite.svg";
import filterIcon from "../../../assets/icon/filter-icon-filled.svg";

import { useHistory } from "react-router-dom";
import Heading from "../Heading";
import { Badge, Collapse, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NotificationIcon from "../../../assets/icon/notification.svg";
import NotificationWhiteIcon from "../../../assets/icon/notificationWhite.svg";
import CartIcon from '../../../assets/icon/cart.svg'
import CartwhiteIcon from '../../../assets/icon/cart-white.svg'
import PlaceholderIcon from "../../../assets/images/placeholder.svg";
import PlaceholderSsvmIcon from "../../../assets/images/placeholder-ssvm.svg";
import PlaceholderDarkIcon from "../../../assets/images/placeholder-dark.svg";
import PlaceholderSsvmDarkIcon from "../../../assets/images/placeholder-ssvm-dark.svg";
import AppLogo from '../../../assets/images/app_name.png'
import { LearnService } from "../../../services/LearnService/learn.service";
import { CaretDownOutlined } from "@ant-design/icons";
import { Course, Student } from "../../../models/student.model";
import { LocalStorage } from "../../utils/LocalStorageHelpers";
import ProfileModal from "./ProfileModal";
import SwitchProfile from "../SwitchProfile";
import NotificationsModal from "./NotificationsModal";
import { Parent } from "../../../models/user.model";
import {
  NotificationMeta,
  Notifications,
} from "../../../models/Notifications/notifications.model";
import NotificationService from "../../../services/NotificationService/notifications.service";
import { debounce } from "lodash";

const { Panel } = Collapse;
interface NavbarProps extends ITitleState {
  collapsable: boolean;
  // currentProfile: any;
  currentCourse: Course;
  currentProduct: any;
  isTrialCourse: boolean;
  setCurrentCourse: (course: any) => void;
  setType: (type: any) => void;
  setUnAuthenticated: () => void;
  setIsTrailIsExpired: (istrail: boolean, isExpired: boolean) => void;
  setTitle: Function;
  authenticated: boolean;
  user: Student;
  app_name?: string;
  notificationsCount: {liveClassCount: number, worksheetCount: number, messagesCount: number}
}

const Navbar: FC<NavbarProps> = ({
  title,
  showBack,
  subText,
  collapsable,
  showSearch,
  searchText,
  color,
  float = false,
  twoBgs,
  // currentProfile,
  isTrialCourse,
  currentCourse,
  currentProduct,
  setCurrentCourse,
  setType,
  setUnAuthenticated,
  setTitle,
  setIsTrailIsExpired,
  authenticated,
  user,
  app_name,
  notificationsCount,
  ...rest
}) => {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  const [courses, setCourses] = useState<Course[]>([]);
  const [showProfile, setProfileVisibility] = useState(false);
  const [showNotifications, setNotificationsVisibility] = useState(false);
  const [showSwitchProfile, setSwitchProfileVisibility] = useState(false);
  const [activeCourse, setActiveCourse] = useState<Course>();
  const [notifications, setNotifications] = useState<Notifications[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [messagesCount, setMessagesCount] = useState(0)
  const [liveClassCount, setLiveClassCount] = useState(0)
  const [worksheetCount, setWorksheetCount] = useState(0)


  const fetchNotifications = (params: any) => {
    NotificationService.fetchNotifications(
      params,
      (data: Notifications[], meta: NotificationMeta) => {
        setNotifications(data);
        setPaginationMeta(meta);
      },
      () => { },
      () => { }
    );
  };

  const fetchCourses = () => {
    LearnService.getStudentCourses(
      (courses: Course[]) => {
        let newC = courses?.filter((course) => {
          if (course?.isActive && (currentProduct?.course?.find((object) => object.id === course?.course?.classRoom?.id) !== undefined)) {
            let istrail = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.istrial
            let enddate = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.enddate;
            let isExpired = moment().isAfter(enddate, 'day');
            if (istrail === true || isExpired === false)
              return course;
          }
        });

        setCourses(newC);

        newC?.forEach((course) => {
          if (course?.isActive) {
            let istrail = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.istrial
            let enddate = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.enddate;
            let isExpired = moment().isAfter(enddate, 'day');

            setIsTrailIsExpired(istrail, isExpired)
            setCurrentCourse(course);
            setType(course?.course?.type);
            setActiveCourse(course);
            //console.log(course);
            LocalStorage.setItem("CURRENT_COURSE", course);
            LocalStorage.setItem("COURSE_ID", course?.id);
            return;
          }
        });
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchCourses();
    fetchNotifications({ page: 1 });
  }, [user]);

  const handlePageChange = (page: number, pageSize: any) => {
    fetchNotifications({ page });
    setCurrentPageNumber(page);
  };

  const debouncedSearchHandler = useMemo(() => debounce((search) => {
    setTitle({ onSearch: search })
  }, 500), []);

  const profilePicSrc = () => {
    const isSsvmKidscasa = app_name === 'ssvmkidscasa';
    const isDark = user?.displayPictureUrl || twoBgs;

    return isSsvmKidscasa ?
      (isDark ? PlaceholderSsvmDarkIcon : PlaceholderSsvmIcon) :
      (isDark ? PlaceholderDarkIcon : PlaceholderIcon);
};

  return title ? (
    <div className={float ? "navbar-container__float" : "navbar__container"}>
      <ProfileModal
        courses={courses}
        visible={showProfile}
        closeHandler={() => setProfileVisibility(false)}
        onClickSwitchProfile={() => setSwitchProfileVisibility(true)}
      />
      <NotificationsModal
        refreshList={() => fetchNotifications({ page: currentPageNumber })}
        notifications={notifications}
        visible={showNotifications}
        closeHandler={() => setNotificationsVisibility(false)}
        paginationMeta={paginationMeta}
        handlePageChange={handlePageChange}
      />
      <SwitchProfile
        visible={showSwitchProfile}
        handleCancel={() => setSwitchProfileVisibility(false)}
        handleOK={() => { }}
        setVisible={(value: any) => setSwitchProfileVisibility(value)}
      />
      <div className="content-left">
        <div className={`heading ${!showBack ? "" : "ml-5"}`}>
          {showBack ? (
            <img
              src={twoBgs ? BackWhiteIcon : BackIcon}
              alt=""
              onClick={handleGoBack}
            />
          ) : null}
          {title !== 'hide' && <Heading color={color}>{title || null}</Heading>}
        </div>

        <p className={`subtext ${showBack ? "subtext--margin" : ""}`}>{subText}</p>
      </div>
      <div className='app-logo' >
        <img src={AppLogo} alt="" />
      </div>

      <div className="content-right">
        <>
          {showSearch && (
            <div className="search-input__container">
              <Input
                disabled={isTrialCourse}
                prefix={<SearchOutlined />}
                className="search-input"
                placeholder={searchText}
                onChange={(e: any) => debouncedSearchHandler(e?.target?.value)}
              />
            </div>
          )}
          <div className="notification-container">
            <img
              onClick={() => history.push('/cart')}
              src={twoBgs ? CartwhiteIcon : CartIcon}
            />
          </div>
          <div className="notification-container">
            <Badge count={notificationsCount.liveClassCount+notificationsCount.messagesCount+notificationsCount.worksheetCount} className={app_name}>
              <img
                onClick={() => setNotificationsVisibility(true)}
                src={twoBgs ? NotificationWhiteIcon : NotificationIcon}
              />
            </Badge>
          </div>
          <div
            className="profile__container"
            onClick={() => setProfileVisibility(true)}
          >
            <div className="profile-image__container">
              <img src={profilePicSrc()} />
            </div>
            <div className="profile-name__container">
              <p className={`profile__name ${twoBgs && "text-white"}`}>
                {`${user?.firstName}`}
                <span>
                  <CaretDownOutlined />
                </span>
              </p>
              <p>
                {currentCourse?.course?.classRoom?.name ||
                  currentCourse?.course?.exam?.acronym}
              </p>
            </div>
          </div>
        </>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default AuthContainer(TitleContainer(Navbar));
