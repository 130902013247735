import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  NotificationMeta,
  Notifications,
} from "../../models/Notifications/notifications.model";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router-dom";

export default class NotificationService {
  static fetchNotifications(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.LIST_NOTIFICATIONS, {
        params: params,
      })
      .then((response) => {
        const data = deserialize(Notifications, response.data["notifications"]);
        const meta = deserialize(NotificationMeta, response.data["meta"]);
        onSuccess(data, meta);
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showNotification(
    notificationId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.SINGLE_NOTIFICATION, {
      notificationId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(Notifications, response.data["notification"]);
        onSuccess(data);
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notification",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static readNotification(
    notificationId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.VIEW_NOTIFICATION, {
      notificationId,
    });
    axiosInstance
      .put(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
      
        onError();
      })
      .finally(onFinal);
  }

  static deleteAllNotifications(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CLEAR_NOTIFICATIONS;

    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Problem in clearing notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static deleteNotification(
    notificationId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.SINGLE_NOTIFICATION, {
      notificationId,
    });

    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Problem in deleting notification",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static messagesCount(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.MESSAGES_COUNT)
      .then((response) => {
        onSuccess(response.data.count);
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static viewAllMessages(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.VIEW_ALL_MESSAGES)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static liveClasssCount(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.LIVE_CLASS_COUNT)
      .then((response) => {
        onSuccess(response.data.count);
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static viewAllLiveClasses(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.VIEW_ALL_LIVE_CLASSES)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static wroksheetCount(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.WORKSHEET_COUNT)
      .then((response) => {
        onSuccess(response.data.count);
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static viewAllWorkSheets(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.VIEW_ALL_WORKSHEET)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Problem in fetching notifications",
          description: "Please try again later!",
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
