import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory, generatePath } from "react-router";

import { Col, Row, Skeleton } from "antd";
import UIButton from "../../../shared/components/UIButton";
import EmptyState from "../../../shared/components/EmptyState";
import AssignmentCard from "./AssignmentCard";

import LeaderboardCupIcon from "../../../assets/icon/cup.svg";
import PlaceholderIcon from "../../../assets/images/placeholder-dark.svg";
import PointsIcon from "../../../assets/icon/points.svg";

import TitleContainer from "../../../store/container/TitleContainer";
import AuthContainer from "../../../store/container/AuthContainer";

import { Course, Student } from "../../../models/student.model";
import { WorksheetModel } from "../../../models/Worksheet/worksheet.model";
import WorksheetService from "../../../services/WorksheetService/Worksheet.service";

import { ASSIGNMENT_DETAILS } from "../../../routes/routeConstants/appRoutes";

import "./assignment.scss";
import PaginationComponent from "../../../shared/components/PaginationComponent";
import NotificationService from "../../../services/NotificationService/notifications.service";




export const SkeletonLoader = (): any => {
    return Array(2)
        .fill(" ")
        .map((value) => {
            return (
                <div className="skeleton-loader">
                    <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
                </div>
            );
        });
};


let LearnCard = ({ appName, ...rest }) => {
    try {
        return <div style={{ background: `url(${require(`../../../assets/${appName}/carousel-bg.svg`)}) no-repeat` }}  {...rest} />;
    }
    catch (err) {
        return <div style={{ background: `url(${require('../../../assets/images/carousel-bg.svg')}) no-repeat` }} {...rest} />;
    }
}


interface AssignmentProps {
    setTitle: Function;
    // currentProfile: any;
    user: Student;
    currentCourse: Course;
    app_name: string;
    setNotificationsCount: Function;
    notificationsCount: {liveClassCount: number, worksheetCount: number, messagesCount: number}
}

const Assignment: FC<AssignmentProps> = ({ setTitle, user, currentCourse, app_name, setNotificationsCount, notificationsCount }) => {
    const history = useHistory();

    const [worksheet, setWorksheet] = useState<WorksheetModel[]>([]);
    const [paginationMeta, setPaginationMeta] = useState<any>({});
    const [loadinWorksheets, setLoadinWorksheets] = useState(false);

    const fetchWorksheetList = (
        pageNumber: number,
        perPage: number,
        searchString?: string
    ) => {
        setLoadinWorksheets(true);
        let queryParams = { StudentId: user.id, CurrentPage: pageNumber, Pagesize: perPage }
        WorksheetService.fetchWorksheets(
            queryParams,
            (worksheets: any, pageMeta: any) => {
                setWorksheet(worksheets);
                setPaginationMeta(pageMeta);
            },
            () => { },
            () => {
                setLoadinWorksheets(false);
            }
        );
    };

    useEffect(() => {
        setTitle({
            title: `Activity`,
            showBack: false,
            subText: "You are doing good, Keep it up!",
            icon: null,
            showSearch: false,
            float: false,
            searchText: "Search subjects, topics ...",
            twoBgs: false,
        });
        fetchWorksheetList(1, 10, "");
    }, []);

    useEffect(() => {
        NotificationService.viewAllWorkSheets(
          () => (notificationsCount.worksheetCount > 0 ? setNotificationsCount({...notificationsCount, worksheetCount: 0}) : null),
          ()=> {},
          ()=> {},
        )
    }, [])

    const handlePageChange = (page: number, pageSize: any) => {
        fetchWorksheetList(page, pageSize, "");
    };

    return (
        <div className="assignment__container">
            <Row>
                <Col className="assignment__carousel__wrapper" span={24}>
                    <LearnCard appName={app_name} className="assignment-card__container">
                        <div className="content__left">
                            <div className="profile-image__container">
                                <img
                                    src={user?.displayPictureUrl ?? PlaceholderIcon}
                                />
                            </div>
                            <div className="profile-details__container">
                                <p className="profile__name">
                                    Welcome <span>{`${user?.firstName}`}</span>
                                </p>
                                <p className="profile__class">
                                    {currentCourse?.course?.classRoom?.name ||
                                        currentCourse?.course?.exam?.name}
                                </p>
                            </div>
                        </div>
                        {/*<div className="content__right">
                            <div className="leaderboard__cup">
                                <img src={LeaderboardCupIcon} />
                            </div>
                            <div className="points__container">
                                <p className="points">
                                    245 Points{" "}
                                    <span>
                                        <img src={PointsIcon} />
                                    </span>
                                </p>
                                <div className="points__btn">
                                    <UIButton secondary type="button">
                                        View Leaderboard
                                    </UIButton>
                                </div>
                            </div>
                                    </div>*/}
                    </LearnCard>
                </Col>
            </Row>

            {loadinWorksheets || worksheet?.length ? (
                <div className="activity-class-activity-now">

                    {loadinWorksheets ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {
                                worksheet?.map((record, index) => {
                                    return (
                                        <AssignmentCard
                                            data={record}
                                            isLive
                                            onClick={() => {
                                                history.push(
                                                    generatePath(ASSIGNMENT_DETAILS, {
                                                        assignmentId: record?.worksheetId,
                                                    }),
                                                    record
                                                )
                                            }}
                                        />
                                    );
                                })
                            }
                            <div className="pagination__footer">
                                <PaginationComponent
                                    handlePageChange={handlePageChange}
                                    paginationMeta={paginationMeta}
                                />
                            </div>
                        </>

                    )}
                </div>
            ) : (
                <EmptyState text={"No activites found."} />
            )}

        </div>
    )
}

export default AuthContainer(TitleContainer(Assignment))