import React, { useEffect, FC, useState } from "react";
import { useHistory, generatePath } from "react-router";

import { Skeleton } from "antd";
import EmptyState from "../../../shared/components/EmptyState";

import AnnouncementCard from "./AnnouncementCard";

import { Course, Student } from "../../../models/student.model";
import { AnnouncementModel } from "../../../models/Announcement/Announcement.model";
import AnnouncementService from "../../../services/AnnouncementService/Announcement.service";

import { ANNOUNCEMENT_DETAILS } from "../../../routes/routeConstants/appRoutes";

import TitleContainer from "../../../store/container/TitleContainer";
import AuthContainer from "../../../store/container/AuthContainer";

import "./announcement.scss";
import PaginationComponent from "../../../shared/components/PaginationComponent";
import NotificationService from "../../../services/NotificationService/notifications.service";




export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};

interface AnnouncementProps {
  setTitle: Function;
  // currentProfile: any;
  user: Student;
  currentCourse: Course;
  app_name: string;
  setNotificationsCount: Function;
  notificationsCount: {liveClassCount: number, worksheetCount: number, messagesCount: number}
}

const Announcement: FC<AnnouncementProps> = ({ setTitle, user, currentCourse, app_name, setNotificationsCount, notificationsCount }) => {
  const history = useHistory();

  const [filters, setFilters] = useState<any>({ CurrentPage: 1, Pagesize: 10 });
  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>({});
  const [loadingAnnouncements, setLoadinAnnouncements] = useState(false);

  const fetchAnnouncements = (queryParams: any) => {
    setLoadinAnnouncements(true);
    AnnouncementService.fetchAnnouncements(
      { studentId: user.id, ...queryParams },
      (announcements: any, pageMeta: any) => {
        setAnnouncements(announcements);
        setPaginationMeta(pageMeta);
      },
      () => { },
      () => {
        setLoadinAnnouncements(false);
      }
    );
  };


  const refreshHandler = () => {
    fetchAnnouncements(filters);
  }


  useEffect(() => {
    setTitle({
      title: `Messages`,
      showBack: false,
      subText: "",
      icon: null,
      showSearch: false,
      float: false,
      searchText: "Search subjects, topics ...",
      twoBgs: true,
      color: "#FFF",
    });
    fetchAnnouncements(filters);
  }, []);


  useEffect(() => {
    NotificationService.viewAllMessages(
      ()  => (notificationsCount.messagesCount > 0 ? setNotificationsCount({...notificationsCount, messagesCount: 0}) : null),
      ()  => {},
      ()  => {},
    )
  }, [])

  const handlePageChange = (page: number, pageSize: any) => {
    fetchAnnouncements({ ...filters, CurrentPage: page, Pagesize: pageSize });
    setFilters({ ...filters, CurrentPage: page, Pagesize: pageSize });
  };


  return (

    <div className="announcement-class">
      {loadingAnnouncements || announcements?.length ? (
        <div className="announcement-class-announcement-now">

          {loadingAnnouncements ? (
            <SkeletonLoader />
          ) : (<>
            <h3>Latest Post</h3>
            {announcements?.map((record, index) => {
              return (
                <AnnouncementCard
                  key={record.announcementId}
                  refreshList={refreshHandler}
                  data={record}
                  onClick={() => {
                    history.push(
                      generatePath(ANNOUNCEMENT_DETAILS, {
                        announcementId: record?.announcementId,
                      }),
                      record
                    )
                  }}
                />
              );
            })}

            <div className="pagination__footer">
              <PaginationComponent
                handlePageChange={handlePageChange}
                paginationMeta={paginationMeta}
              />
            </div>
          </>
          )
          }
        </div>
      ) : (
        <EmptyState text={"No messages found."} />
      )}

    </div>
  );
};

export default AuthContainer(TitleContainer((Announcement)))
