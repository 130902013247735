import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {  setUnAuthenticated,
    setCurrentCourse,
    setType,
    setCurrentProfile,
    setAuthenticated,
    setProductDetails,
    setIsTrailIsExpired,
    setAppName,
    setNotificationsCount
} from "../actions/auth";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        // currentProfile: state.auth.currentProfile,
        currentCourse: state.auth.currentCourse,
        type :state.auth.type,
        currentProduct:state.auth.productDetails,
        isTrialCourse:state.auth.isTrialCourse,
        isCourseExpired:state.auth.isCourseExpired,
        app_name:state.auth.app_name,
        notificationsCount: state.auth.notifications,
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setUnAuthenticated,
    setCurrentCourse,
    // setCurrentProfile,
    setType,
    setAuthenticated,
    setIsTrailIsExpired,
    setProductDetails,
    setAppName,
    setNotificationsCount
}, dispatch);

const AuthContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AuthContainer;
